import { useQuery } from '@tanstack/react-query';

import { getAgents } from '@/app/actions/getAgents';
import { Agent } from '@/components/messenger/types';

type AgentResponse = Agent[] | null;

type GetWorkspaceAgentsProps = {
  workspaceId?: string;
};

const fetchWorkspaceAgents = async ({
  workspaceId,
}: GetWorkspaceAgentsProps) => {
  if (!workspaceId) return null;
  const agents = await getAgents({ workspaceId });
  return agents;
};

export function useGetWorkspaceAgents({
  workspaceId,
}: GetWorkspaceAgentsProps) {
  return useQuery<AgentResponse>({
    enabled: !!workspaceId,
    queryKey: ['agents', workspaceId],
    queryFn: () => fetchWorkspaceAgents({ workspaceId }),
  });
}
